<template>
  <div>
    <div
      v-if="selectedWarehouse && selectedWarehouse?.type !== 3"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("order.thisWarehouseNotARetailType") }}
    </div>
    <div class="row gap-3" v-else>
      <div class="col">
        <router-link to="/sales/collects" class="btn btn-secondary btn-no-r">
          <span><BaseIcon name="active" /> {{ $t("order.collectList") }}</span>
        </router-link>
        <router-link
          to="/sales/products"
          class="btn btn-secondary btn-no-r btn-no-l"
        >
          <span><BaseIcon name="box" /> {{ $t("order.packingList") }}</span>
        </router-link>
        <router-link
          to="/sales/delivery-notes"
          class="btn btn-primary btn-no-r btn-no-l"
        >
          <span
            ><BaseIcon name="delivery-info" />
            {{ $t("salesCollectList.deliveryNotes") }}</span
          >
        </router-link>
        <router-link to="/sales/return" class="btn btn-secondary btn-no-l">
          <span
            ><BaseIcon name="truck-remove" /> {{ $t("order.returnList") }}</span
          >
        </router-link>
      </div>
      <div class="col-auto text-end">
        <button v-on:click="loadData" class="btn btn-type-2 btn-small">
          <span
            ><BaseIcon name="refresh" />
            {{ $t("salesCollectList.refresh") }}</span
          >
        </button>
      </div>
      <div class="col-12" v-if="notes === undefined">
        <div
          v-for="i in 3"
          :key="i"
          class="nature-white-box no-min-height mb-3"
        >
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>
                <Skeletor :width="Math.random() * 21 + 30 + '%'" />
              </h2>
              <div>
                <span>
                  <Skeletor :width="Math.random() * 10 + 10 + '%'" />
                </span>
              </div>
            </div>
          </div>
          <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.3"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.6"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.9"
          />
        </div>
      </div>
      <div v-else class="col-12">
        <div v-if="notes.length === 0" class="text-center mt-4">
          <Vue3Lottie
            :animationData="emptyJSON"
            :height="160"
            :width="300"
            :key="key"
          />
          <div class="bold-40 m-2 p-5">
            {{ $t("salesProductList.thereIsNothingToPack") }}
          </div>
        </div>
        <div
          class="nature-white-box no-min-height mb-3"
          v-for="note in notes"
          :key="note.id"
        >
          <div class="white-box-top"></div>
          <div class="row mt-3 mb-4 ms-2">
            <div class="col row">
              <img
                :src="note.logo"
                alt=""
                v-if="note.logo"
                class="col-1 img-thumbnail"
              />
              <h2 class="col">
                {{ note.name }} - {{ formatDate(note.planned_pickup_date) }}
              </h2>
            </div>
            <div class="col-auto mt-2 me-3">
              <button
                class="btn btn-success p-2"
                data-bs-placement="top"
                :title="$t(`salesProductList.printLabel`)"
                :disabled="note.loadPrintBtn"
                v-on:click="printNote(note)"
                :class="{ 'loading-spin': note.loadPrintBtn }"
                v-if="note.is_closed || !note.has_printable_notes"
              >
                <BaseIcon name="printer" />
              </button>
              <button
                class="btn btn-primary p-2"
                data-bs-placement="top"
                :title="$t(`order.createDelivery`)"
                :disabled="note.loadCloseBtn"
                v-on:click="closeNote(note)"
                :class="{ 'loading-spin': note.loadCloseBtn }"
                v-else
              >
                <BaseIcon name="box-add" />
              </button>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="note.orderDeliveries"
            :show-add="false"
            :show-edit="false"
            :show-del="false"
            type="deliveries"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OneByOneTable from "../../components/lists/OneByOneTable";
import http from "@/modules/http";
import BaseIcon from "@/components/icons/BaseIcon";
import ws from "@/modules/ws";
import { useStore } from "vuex";
import deliveredStatuses from "@/modules/DeliveredStatuses";
import { Vue3Lottie } from "vue3-lottie";
import emptyJSON from "../../assets/animations/ghost.json";
import date from "../../modules/date";
import printJS from "print-js";
import tenant from "../../modules/tenant";

export default {
  name: "SalesDeliveryNotes",
  components: { Vue3Lottie, BaseIcon, OneByOneTable },
  data() {
    return {
      store: useStore(),
      deliveredStatuses: deliveredStatuses,
      emptyJSON: emptyJSON,
      rows: [
        {
          name: "Tracking code",
          key: "public_tracking_code",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Type",
          show: true,
          editable: false,
          key: "type",
          type: "enum",
          label: "name",
          values: {
            1: { id: 1, name: "Normal delivery" },
            2: { id: 2, name: "Pick-up point" },
            3: { id: 3, name: "Return" },
            4: { id: 4, name: "Change" },
            5: { id: 5, name: "Next day delivery" },
            6: { id: 6, name: "Saturday delivery" },
            7: { id: 7, name: "Flexible day delivery" },
            8: { id: 8, name: "Timeslot delivery" },
            9: { id: 9, name: "Drop off return" },
          },
        },
        {
          name: "Delivery to",
          key: "city",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Packed at",
          key: "packaged_at",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "Boxed at",
          key: "tracking_code_created_at",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "Delivered at",
          key: "delivered_at",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "Refused at",
          key: "refused_at",
          type: "date",
          show: true,
          editable: false,
        },
      ],
      notes: undefined,
      itemsById: {},
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    q() {
      return this.store.state.topBar.q;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    storageUrl() {
      return process.env.VUE_APP_FILES;
    },
  },
  watch: {
    warehouse() {
      this.loadData();
    },
  },
  mounted() {
    ws.init();

    ws.subscribe("modify", "deliveryNote", (e) => {
      if (
        e.rowKey === "collected" ||
        e.rowKey === "in_package" ||
        e.rowKey === "quantity" ||
        e.rowKey === "returned" ||
        e.rowKey === "need_to_sent_quantity"
      ) {
        let obj = this.itemsById[e.objectId];
        if (obj !== undefined) {
          obj[e.rowKey] = e.value;
        }
      }
    });

    this.loadData();
  },
  methods: {
    formatDate(val) {
      return date.format(val, false, false);
    },
    loadData() {
      this.notes = undefined;
      let warehouse = this.warehouse;
      if (this.warehouse === undefined || this.warehouse === null) {
        warehouse = "";
      }
      http.fetch(`/delivery/notes?warehouse=${warehouse}`).then((data) => {
        this.notes = data.data;
        this.itemsById = {};
        for (const key in this.notes) {
          this.itemsById[this.notes[key].id] = this.notes[key];
          if (
            this.notes[key].logo !== null &&
            this.notes[key].logo.indexOf("http") !== 0 &&
            this.notes[key].logo.indexOf("//") !== 0
          ) {
            this.notes[key].logo = this.storageUrl + "/" + this.notes[key].logo;
          }
        }
      });
    },
    closeNote(note) {
      note.loadCloseBtn = true;
      http.fetch(`/delivery/notes/close`, { id: note.id }).then(() => {
        note.loadCloseBtn = false;
        this.itemsById[note.id].is_closed = true;
      });
    },
    printNote(note) {
      note.loadPrintBtn = true;

      let url = `${tenant.getApiBaseUrl()}/delivery/notes/${
        note.id
      }/print/?_token=${this.store.state.token}`;

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        note.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            note.loadPrintBtn = false;
          },
        });
      }
    },
  },
};
</script>
